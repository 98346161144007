import { INFO_MESSAGE_PAGE_PARAMS } from '~/constants/infoMessages';
import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware((to) => {
  const { getUserData } = useAuthStore();

  if (
    !Object.values(INFO_MESSAGE_PAGE_PARAMS).includes(to.params?.pageType as string)
    || (to.params?.pageType === INFO_MESSAGE_PAGE_PARAMS.login && !getUserData.loggedIn)
    || (to.params?.pageType === INFO_MESSAGE_PAGE_PARAMS.sessionExpired && getUserData.loggedIn)
  ) {
    return navigateTo({ path: '/' });
  }
});
